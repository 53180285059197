import {getTenant} from "../helpers";

export const assignContent = (contentType="course", data, callback, /*isGroup=false, unassign=false*/) => {
    const currentTenant = getTenant()
    const dataToSubmit = {
        ...data,
        platform_key:currentTenant,
    }
    const url = process.env.REACT_APP_IBL_DM_URL + `/api/catalog/suggestions/${contentType}/manage/`//assignment/${isGroup ? "group/" : ""}${unassign ? "?"+new URLSearchParams(dataToSubmit).toString() : ""}`
    fetch(url, {
        method: "POST",//!unassign ? 'POST' : 'DELETE',
        body: JSON.stringify(dataToSubmit),
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + localStorage.getItem('dm_token'),
        },
    }).then((resp) => {
        callback(resp.status)
    }).catch(err => {
        //
    });
}