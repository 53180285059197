import {useEffect, useState} from 'react';
import {getLicensingUserGroupList, getLicensingUsersListInGroup} from "./utils/licensing";
import {useDebounce} from "./useDebounce";  // Replace with the correct path

const useLicensingUsersInGroup = ({groupID, search="", page=1, size=10, refresher=null}) => {
    const [users, setUsers] = useState([]);
    const [usersLoading, setUsersLoading] = useState(false);
    const [pagination, setPagination] = useState({
        totalItems:0,
        currentPage:1,
        totalPages:0,
        pageSize:0
    });

    const handleUsersListRetrieval = (data) => {
        setUsers(Array.isArray(data?.results) ? data.results : [])
        setPagination({
            totalItems:  data?.count,
            currentPage: page,
            totalPages: Math.ceil(data?.count / size),
            pageSize: size,
        });
        setUsersLoading(false)
    }

    useDebounce(()=>{
        setUsersLoading(true)
        getLicensingUsersListInGroup( {
            page_size:size,
            group_id:groupID,
            page,
            ...(!!search ? {
                query:search
            } : {})
        }, handleUsersListRetrieval, handleUsersListRetrieval)
    }, 200, [search, page, size, refresher])

    return {
        users,
        usersLoading,
        pagination
    }


};

export default useLicensingUsersInGroup;
