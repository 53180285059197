import React, { useRef, useState } from 'react';
import Select from 'react-select';
import Async from 'react-select/async';
import useLicensingUsers from '../../hooks/useLicensingUsers';
import useAssignLicense from '../../hooks/useAssignLicense';
import { getTableSizes } from '../../hooks/helpers';
import './AssignLicense.css';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';
import { useDebounce } from '../../hooks/useDebounce';

const AssignLicense = ({ setFocused, licenseType = 'user' }) => {
  const TABLE_SIZES = getTableSizes();
  const INDIVIDUAL_TAB_SLUG = 'individual';
  const GROUPS_TAB_SLUG = 'groups';
  const [focusedInnerTab, setFocusedInnerTab] = useState(INDIVIDUAL_TAB_SLUG);
  const [search, setSearch] = useState('');
  const [size, setSize] = useState(TABLE_SIZES[0]);
  const [page, setPage] = useState(1);
  const [submitting, setSubmitting] = useState(false);
  const [notification, setNotification] = useState({
    success: null,
    msg: null,
  });
  const [enableAddingUnregisteredUsers, setEnableAddingUnregisteredUsers] =
    useState(false);
  const unRegisteredEmailFieldRef = useRef(null);

  useDebounce(
    () => {
      if (enableAddingUnregisteredUsers) {
        unRegisteredEmailFieldRef?.current?.focus();
      }
    },
    200,
    [enableAddingUnregisteredUsers]
  );

  const handleAddingUnregisteredUsersCheck = (e) => {
    setEnableAddingUnregisteredUsers(!enableAddingUnregisteredUsers);
  };

  const [fields, setFields] = useState({
    license: null,
    user: null,
    group: null,
    email: null,
  });

  const { getUsersForSelectDropdown } = useLicensingUsers({
    search,
    size,
    page,
  });
  const {
    getLicensesForSelectDropdown,
    getUserGroupsForSelectDropdown,
    setAssignLicense,
  } = useAssignLicense({ platformType: licenseType, search, size, page });

  const handleUserGroupChange = (choice) => {
    setFields({
      ...fields,
      group: choice,
    });
  };

  const licensePromiseOptions = (inputValue) => {
    return new Promise((resolve) => {
      getLicensesForSelectDropdown(inputValue, (data) => {
        const result = data?.results;
        if (Array.isArray(result) && result.length > 0) {
          resolve(
            result
              .filter((pre) => pre.name !== '')
              .map((license) => {
                return {
                  value: license.id,
                  label: license.name,
                };
              })
          );
        } else {
          resolve([]);
        }
      });
    });
  };
  const userGroupsPromiseOptions = (inputValue) => {
    return new Promise((resolve) => {
      getUserGroupsForSelectDropdown(inputValue, (data) => {
        const result = data?.results;
        if (Array.isArray(result) && result.length > 0) {
          resolve(
            result
              .filter((pre) => pre.name !== '')
              .map((group) => {
                return {
                  value: group.group_id,
                  label: group?.name,
                };
              })
          );
        } else {
          resolve([]);
        }
      });
    });
  };

  const usersPromiseOptions = (inputValue) => {
    return new Promise((resolve) => {
      getUsersForSelectDropdown(inputValue, (data) => {
        const result = data?.results;
        if (Array.isArray(result) && result.length > 0) {
          resolve(
            result
              .filter((pre) => pre.name !== '')
              .map((user) => {
                return {
                  value: user.user_id,
                  label: user.name,
                };
              })
          );
        } else {
          resolve([]);
        }
      });
    });
  };

  const handleLicenseChange = (choice) => {
    setFields({
      ...fields,
      license: choice,
    });
  };
  const handleUserChange = (choice) => {
    setFields({
      ...fields,
      user: choice,
    });
  };

  const handleSetNotification = (success, msg) => {
    setNotification({
      msg: msg,
      success,
    });
    setTimeout(() => {
      setNotification({});
    }, 2500);
  };

  const handleSubmitCallback = (status) => {
    setSubmitting(false);
    if ([201, 200].includes(status)) {
      //success
      handleSetNotification(true, 'License assigned successfully!');
      setFields({
        license: null,
        user: null,
        group: null,
        email: null,
      });
      setEnableAddingUnregisteredUsers(false);
    } else if (status === 400) {
      handleSetNotification(false, 'Fill out the fields correctly');
    } else {
      handleSetNotification(false, 'An error occurred. Please try again!');
    }
  };

  const handleSubmit = () => {
    setSubmitting(true);
    const isGroup = focusedInnerTab === GROUPS_TAB_SLUG;
    const dataToSubmit = {
      license_id: fields?.license?.value,
      isGroup,
      ...(!isGroup
        ? !enableAddingUnregisteredUsers
          ? {
              user_id: fields?.user?.value,
            }
          : {
              email: fields?.email,
            }
        : {
            group_id: fields?.group?.value,
          }),
    };

    setAssignLicense(dataToSubmit, handleSubmitCallback);
  };

  return (
    <div
      style={{ display: 'flex' }}
      react-component="InvitePopup"
      className="popup_div-nm_popup assign-license-popup"
    >
      <div className="popup_cont-sk">
        <div className="hf_popup">
          <div className="div-block-147 new_msg">
            <div className="text-block-135 mg-0">Assign License</div>
            {notification?.msg && (
              <div
                className={`license-notification-block ${!notification?.success ? 'error' : ''}`}
              >
                {notification?.msg}
              </div>
            )}
          </div>
          <div
            data-current="Platform"
            data-easing="ease"
            data-duration-in="300"
            data-duration-out="100"
            className="tabs w-tabs"
          >
            <div className="w-tab-content">
              <div data-w-tab="Platform" className="w-tab-pane w--tab-active">
                <div className="div-block-228 less-pad">
                  <div className="form-block-7 w-form">
                    <form
                      id="email-form-5"
                      name="email-form-5"
                      data-name="Email Form 5"
                      method="get"
                      data-wf-page-id="66e0598d95b3c617324f4850"
                      data-wf-element-id="04b212b6-6908-556e-c3c3-d1f2508be1ea"
                    >
                      <label
                        htmlFor="field-5"
                        className="text-block-139-bd-f-soc"
                      >
                        License *
                      </label>
                      <Async
                        cacheOptions
                        classNamePrefix={'react-select'}
                        defaultOptions
                        loadOptions={licensePromiseOptions}
                        value={fields?.license}
                        options={fields?.license}
                        onChange={handleLicenseChange}
                        isSearchable={true}
                        placeholder={"Search..."}
                      />
                      <label
                        htmlFor="name-3"
                        className="text-block-139-bd-f-soc"
                      >
                        Assign Mode *
                      </label>
                      <div
                        data-current="Individual"
                        data-easing="ease"
                        data-duration-in="300"
                        data-duration-out="100"
                        className="tabs-2 w-tabs"
                      >
                        <div className="tabs-menu-2 w-tab-menu">
                          <a
                            data-w-tab="Individual"
                            onClick={() =>
                              setFocusedInnerTab(INDIVIDUAL_TAB_SLUG)
                            }
                            className={`invite-mode-tab-link w-inline-block w-tab-link ${focusedInnerTab === INDIVIDUAL_TAB_SLUG ? 'w--current' : ''}`}
                          >
                            <div className="w-layout-hflex flex-block-13">
                              <img
                                src="/images/user-12.svg"
                                loading="lazy"
                                alt=""
                                className="image-142"
                              />
                            </div>
                            <h5 className="heading-5">Individual</h5>
                          </a>
                          <a
                            data-w-tab="Bulk"
                            onClick={() => setFocusedInnerTab(GROUPS_TAB_SLUG)}
                            className={`invite-mode-tab-link w-inline-block w-tab-link ${focusedInnerTab === GROUPS_TAB_SLUG ? 'w--current' : ''}`}
                          >
                            <div className="w-layout-hflex flex-block-13">
                              <img
                                src="/images/users-4.svg"
                                loading="lazy"
                                alt=""
                                className="image-142"
                              />
                            </div>
                            <h5 className="heading-5">Group</h5>
                          </a>
                        </div>
                        <div className="tabs-content w-tab-content">
                          {focusedInnerTab === INDIVIDUAL_TAB_SLUG && (
                            <div
                              data-w-tab="Individual"
                              className="w-tab-pane w--tab-active"
                            >
                              <div className="w-layout-hflex flex-block-29">
                                <label
                                  htmlFor="name-3"
                                  className="text-block-139-bd-f-soc"
                                >
                                  Individual User *
                                </label>
                                <div className="w-layout-hflex flex-block-30">
                                  <div className="email-switching-block justify-start-flex w-embed">
                                    <label className="user-switcher">
                                      <input
                                        onChange={
                                          handleAddingUnregisteredUsersCheck
                                        }
                                        type="checkbox"
                                        checked={enableAddingUnregisteredUsers}
                                      />
                                      <span className="user-switcher-slider round"></span>
                                    </label>
                                  </div>
                                  <label
                                    htmlFor="name-3"
                                    className="text-block-139-bd-f-soc"
                                  >
                                    Add unregistered user
                                  </label>
                                </div>
                              </div>
                              {!enableAddingUnregisteredUsers ? (
                                <Async
                                  cacheOptions
                                  classNamePrefix={'react-select'}
                                  defaultOptions
                                  loadOptions={usersPromiseOptions}
                                  options={fields?.user}
                                  value={fields?.user}
                                  onChange={handleUserChange}
                                  isSearchable={true}
                                  placeholder={"Search..."}
                                />
                              ) : (
                                <div className="w-layout-hflex learner-email-block active">
                                  <input
                                    className="learner-email-input-field w-input"
                                    maxLength="256"
                                    name="field-7"
                                    data-name="Field 7"
                                    placeholder="Type in a learner&#x27;s email"
                                    type="text"
                                    ref={unRegisteredEmailFieldRef}
                                    value={fields?.email}
                                    onChange={(e) =>
                                      setFields((old) => ({
                                        ...old,
                                        email: e?.target?.value,
                                      }))
                                    }
                                    id="field-7"
                                  />
                                </div>
                              )}
                            </div>
                          )}
                          {focusedInnerTab === GROUPS_TAB_SLUG && (
                            <div
                              data-w-tab="Bulk"
                              className="w-tab-pane w--tab-active"
                            >
                              <label
                                htmlFor="name-3"
                                className="text-block-139-bd-f-soc"
                              >
                                Group *
                              </label>
                              <Async
                                cacheOptions
                                classNamePrefix={'react-select'}
                                defaultOptions
                                loadOptions={userGroupsPromiseOptions}
                                options={fields?.group}
                                value={fields?.group}
                                onChange={handleUserGroupChange}
                                placeholder={"Search..."}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>
                        Oops! Something went wrong while submitting the form.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="div-block-147 new_msg">
            <div className="div-block-264 f-w">
              <div
                onClick={() => setFocused(false)}
                data-w-id="04b212b6-6908-556e-c3c3-d1f2508be27f"
                className="div-block-207 first active follow-user alt-btn cancel-invite"
              >
                <div className="text-block-111 red-text cf">Close</div>
              </div>
              <div
                onClick={() => handleSubmit()}
                className="div-block-207 first active follow-user redeem-btn mg-l16"
              >
                <div className="text-block-111 red-text cf">Assign</div>
                {submitting && (
                  <span style={{ marginLeft: '10px' }}>
                    <IBLSpinLoader size={15} color={'#FFF'} />
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignLicense;
