import React from 'react';
import {assignContent} from "./utils/contentAssignment";

const UseContentAssignmentToUser = ({contentType="course", page=1, size=10}) => {

    const setAssignContent = (data, callback) =>{
        assignContent(contentType, data, callback)
    }

    return {
        setAssignContent
    }
};

export default UseContentAssignmentToUser;