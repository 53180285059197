import React, {useEffect, useState} from 'react';
import useLicensingUsersInGroup from "../../hooks/useLicensingUsersInGroup";
import {IBLSpinLoader} from "@iblai/ibl-web-react-common";
import EmptyTableDataInfographic from "../EmptyTableDataInfographic/EmptyTableDataInfographic";
import './LicensingUsersInGroup.css'
import ReactPaginate from "react-paginate";
import {useTranslation} from "react-i18next";

const LicensingUsersInGroup = ({group, setFocused, setAddUsersFocused, refresher=null}) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const limit=10
    const {users, usersLoading, pagination} = useLicensingUsersInGroup({groupID:group?.group_id, search, refresher, page, size:limit})
    const paginate = ({ selected }) => {
        setPage(selected + 1);
    };
    const handleSearch = (e) =>{
        setSearch(e?.target?.value)
        setPage(1)
    }

    return (
        <div style={{display:"flex"}} react-component="InvitePopup" className="popup_div-nm_popup user-group-list-popup">
            <div className="popup_cont-sk">
                <div className="hf_popup p-top p-relative">
                    <div className="div-block-147 new_msg">
                        <div className="text-block-135 mg-0">Users in {group?.name}</div>
                        <div onClick={()=>setAddUsersFocused(true)} data-w-id="75de2058-1c39-c64d-8912-12f8c94a307a" className="div-block-207 first active follow-user create-cred-popup">
                            <div className="text-block-111">Update</div>
                        </div>
                    </div>
                    <div className="w-layout-vflex flex-block-17">
                        <div className="form-block-29 w-form">
                            <form id="email-form-6" name="email-form-6" data-name="Email Form 6" method="get" className="form-19" data-wf-page-id="66e1c2afd7d305ab933f7a97" data-wf-element-id="2948be8d-afba-1f59-42e0-c3c5a5db9d21">
                                <div className="w-layout-hflex flex-block-14"><img src="/images/search-1.svg" loading="lazy" alt="" className="image-143"/>
                                    <input value={search} onChange={handleSearch} className="text-field-10 w-input" maxLength="256" name="field-6" data-name="Field 6" placeholder="Search users" type="text" id="field-6"/>
                                </div>
                            </form>
                            <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                            </div>
                            <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                            </div>
                        </div>
                    </div>
                    <div className="w-layout-vflex flex-block-18">
                        <div className="w-layout-vflex table-container-2">
                            <div className="w-layout-hflex table-header invited-users-table-header">
                                <div className="table-block _45 p-x">
                                    <h5 className="table-title">NAME</h5>
                                </div>
                                <div className="table-block _55">
                                    <h5 className="table-title">EMAIL</h5>
                                </div>
                                {/*<div className="table-block _10"></div>*/}
                            </div>
                            {
                                usersLoading ?
                                    <IBLSpinLoader size={25} color={"#FFF"} containerHeight={"150px"}/>
                                    :
                                    users.length > 0 ?
                                        users.map((user, index) => (
                                            <div key={`group-user-${index}`} className="w-layout-vflex flex-block-19">
                                                <div
                                                    className="w-layout-hflex table-body-row greyish-bg-in-darkmode invited-users-table-row">
                                                    <div className="table-block _45 p-x">
                                                        <div className="table-text">{user?.edx_data?.name}</div>
                                                    </div>
                                                    <div className="table-block _55">
                                                        <div className="table-text">{user?.email}</div>
                                                    </div>
                                                    {/*<div className="table-block _10 y-center"><img
                                                        src="/images/trash-2.svg" loading="lazy" alt=""
                                                        className="image-146"/></div>*/}
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <EmptyTableDataInfographic style={{width: "100%"}}/>
                            }
                            <div className="tf pagination-container">
                                <div className="div-block-289">
                                    <div className="info-3">
                                        {t('Showing')}{' '}
                                        {(page - 1) * limit + 1}{' '}
                                        {t('to')}{' '}
                                        {Math.min(page * limit, pagination.totalItems)}{' '}
                                        {t('of')} {pagination.totalItems}
                                    </div>
                                    {
                                        pagination.totalItems > limit && (
                                            <ReactPaginate
                                                onPageChange={paginate}
                                                pageCount={pagination.totalPages}
                                                forcePage={page - 1}
                                                previousLabel={'Prev'}
                                                nextLabel={'Next'}
                                                containerClassName={'pagination'}
                                                pageLinkClassName={'page-number'}
                                                previousLinkClassName={'page-number'}
                                                nextLinkClassName={'page-number'}
                                                activeLinkClassName={'active'}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div className="w-layout-hflex flex-block-20">
                        <div className="pagination">
                            <div className="arrow-invite-previous-disabled p-item">
                                <div className="text-block fa_icon fa-arrow disabled"></div>
                            </div>
                            <div className="p-active p-item">
                                <div>1</div>
                            </div>
                            <div className="p-item arrow-next disabled">
                                <div className="text-block fa_icon fa-arrow"></div>
                            </div>
                        </div>
                    </div>*/}
                    <div onClick={() => setFocused(null)} data-w-id="4ba06e75-1771-d0a8-0557-d57c1a58e6cd"
                         className="w-layout-hflex flex-block-21"><img src="/images/x-3.svg" loading="lazy" width="24"
                                                                       alt="" className="image-147"/></div>
                </div>
            </div>
        </div>
    );
};

export default LicensingUsersInGroup;